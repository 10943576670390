import { Container, NextButton, PrevButton } from "@util/standard";
import { Swiper as SwiperComp } from "@global";
import { Maybe, SanityOpenHome } from "@graphql-types";
import React, { useRef, useState } from "react";
import { colors, fontWeights } from "@util/constants";
import { em, toDate } from "@util/helper";
import styled from "styled-components";
import { Delete, Edit } from "@util/svg";
import { mediaQuery } from "@styles/mediaQueries";
import { Swiper } from "swiper";

interface Props {
  data?: Maybe<Maybe<SanityOpenHome>[]>;
  loading?: string | boolean;
  onEdit?: (data: SanityOpenHome) => void;
  onDelete?: (data: SanityOpenHome) => void;
  admin?: boolean;
  isPortal?: boolean;
}

const OpenHomesCarousel = ({ data, loading, onEdit, onDelete, admin, isPortal = false }: Props) => {
  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);
  const [swiper, setSwiper] = useState<Swiper>();

  const swiperInit = (swiper: any) => {
    setSwiper(swiper);
  };

  if (!data?.length)
    return (
      <Container flexDirection="column">
        {!isPortal && <h3>Open Homes</h3>}
        <p>No upcoming open homes – Please contact us to arrange a viewing</p>
      </Container>
    );
  return (
    <Wrapper>
      {!isPortal && <h3>Open Homes</h3>}
      <SwiperComp
        slides={
          data?.map(openHome => {
            if (!openHome) return null;
            const { _key, startDate, endTime, startTime } = openHome;
            return (
              <OpenHomeWrapper key={_key} style={{ opacity: loading === _key ? 0.4 : 1 }}>
                <span className="date">{toDate(startDate, "date")}</span>
                <span className="month">{toDate(startDate, "month")}</span>
                {startTime && endTime && (
                  <span className="time">
                    {toDate(`${startDate}T${startTime}`, "time")} -{" "}
                    {toDate(`${startDate}T${endTime}`, "time")}
                  </span>
                )}
                <span className="day">{toDate(startDate, "day")}</span>
                {admin && (
                  <div className="action-buttons">
                    <button
                      disabled={loading === _key}
                      onClick={onEdit ? () => onEdit(openHome) : undefined}
                    >
                      <Edit color="main" />
                    </button>
                    <button
                      disabled={loading === _key}
                      onClick={onDelete ? () => onDelete(openHome) : undefined}
                    >
                      <Delete color="errorRed" />
                    </button>
                  </div>
                )}
              </OpenHomeWrapper>
            );
          }) ?? []
        }
        spaceBetween={10}
        slidesPerView="auto"
        navigation
        onInit={swiper => swiperInit(swiper)}
        isCustomNav
      />
      <div className="slider-nav">
        <PrevButton ref={navigationPrevRef} onClick={() => swiper?.slidePrev()}></PrevButton>
        <NextButton ref={navigationNextRef} onClick={() => swiper?.slideNext()}></NextButton>
      </div>
    </Wrapper>
  );
};

export default OpenHomesCarousel;

const Wrapper = styled.div`
  width: 100%;
  .swiper {
    padding: 0;
  }

  .swiper-slide {
    max-width: 170px;
    padding: 1px;

    &:first-child {
      > div {
        background: ${colors.main};
        color: ${colors.white};
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .slider-nav {
    display: flex;
    gap: 15px;
    margin-top: 16px;

    ${mediaQuery.tabletDown} {
      display: none;
    }

    button {
      margin: 0;
    }
  }
`;

const OpenHomeWrapper = styled.div`
  border-radius: 10px;
  border: 1.5px solid ${colors.main};
  padding: 25px 20px;
  text-align: center;
  line-height: 1.2;
  transition: background 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background: ${colors.main};
    color: ${colors.white};

    .action-buttons {
      opacity: 1;
    }
  }

  .action-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10px;
    right: 10px;
    gap: 8px;
    opacity: 0;
    transition: opacity 300ms;

    button {
      background-color: ${colors.white};
      padding: 8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }

  span {
    display: inline-block;
    width: 100%;
  }

  .date {
    font-size: ${em(28)};
    margin-bottom: 9px;
    font-weight: ${fontWeights.semibold};
  }
  .month {
    margin-bottom: 16px;
  }
  .time {
    font-size: ${em(14)};
  }
  .day {
    font-size: ${em(12)};
  }
`;
