import {
  PropertyIcons,
  SideColumn,
  ExternalLinks,
  Drawer,
  PropertyEoiForm,
  Map,
  PropertyGallery,
} from "@global";
import FooterSections from "@shared/footer/footerSections";
import SEO from "@shared/seo";
import { TABLET_BREAKPOINT, colors, colorsRGB } from "@util/constants";
import { formatPropertyAddress, getPackageCategory, splitCamelCase, toDollar } from "@util/helper";
import { Container, PageWidth, MobileContainer, Section } from "@util/standard";
import { PageContext } from "@util/types";
import React, { useState } from "react";
import styled from "styled-components";
import { Component } from "@components";
import { Maybe, SanityProperty, SanityPropertyTemplate } from "@graphql-types";
import { PropertyContext } from "./utils";
import OpenHomesCarousel from "./openHomeCarousel";
import { mediaQuery } from "@styles/mediaQueries";

interface Props {
  data: {
    sanityPropertyTemplate: Maybe<SanityPropertyTemplate> | undefined;
    sanityProperty: Maybe<SanityProperty>;
  };
  pageContext: PageContext;
}

const PropertyInner = ({ data, pageContext }: Props) => {
  const [formOpen, setFormOpen] = useState(false);
  const { sanityProperty: property, sanityPropertyTemplate } = data;
  const packageCategory = getPackageCategory(property);

  if (!property) return null;

  const {
    address,
    details,
    saleDetails,
    publicDocuments,
    seo,
    geoLocation,
    externalLinks,
    openHomes,
    referenceID,
    heading,
    description,
    status,
    homesellAgent,
    sellerDetails,
    propertyId,
  } = property;

  const isSold = status === "unconditional" || status === "sold";

  return (
    <PropertyContext.Provider
      value={
        {
          isAdmin: false,
          property,
          loading: false,
        } as PropertyContext
      }
    >
      <SEO seoData={seo} slug={pageContext?.pagePath} />
      <Container flexDirection="column">
        <SectionTop>
          <PageWidth display="block">
            <Container flexDirection="column" width="100%">
              <H1 className="h3">{formatPropertyAddress(address)}</H1>
              <Container tabletMargin="0 0 17px" className="price-container">
                {splitCamelCase(saleDetails?.type, "capitalize")} {toDollar(saleDetails?.price)}
              </Container>
              <Container
                alignItems="center"
                justifyContent="space-between"
                tabletFlexFlow="column-reverse"
                tabletAlignItems="flex-start"
              >
                <Container>
                  <PropertyIcons bed={details?.bed} bath={details?.bath} scale={0.8} />
                </Container>
              </Container>
            </Container>
            <div className="content-area">
              <div className="left-side">
                <PropertyGallery />
                <Container flexDirection="column" margin="20px 0 0">
                  {property.referenceID && <p>ID# {referenceID}</p>}
                  <h4>{heading ?? "Description"}</h4>
                  {description && (
                    <Container flexDirection="column" margin="0 0 50px" tabletMargin="0 0 20px">
                      {description.split("\n").map(str => {
                        if (!str.trim()) return;
                        return <p key={str}>{str}</p>;
                      })}
                    </Container>
                  )}
                  <MobileContainer flexDirection="column" margin="0 0 50px" tabletMargin="0 0 20px">
                    <SideColumn
                      files={publicDocuments}
                      globalFiles={sanityPropertyTemplate?.defaultDocuments}
                      setFormOpen={setFormOpen}
                      agent={homesellAgent}
                      owner={sellerDetails}
                      listingType={packageCategory}
                      status={status}
                    />
                  </MobileContainer>

                  <div className="open-homes">
                    <OpenHomesCarousel data={openHomes} />
                  </div>
                  {externalLinks && externalLinks.length > 0 && (
                    <ExternalLinks data={externalLinks} />
                  )}
                  <Container margin="0 0 50px" tabletMargin="0 0 20px">
                    <Map geoLocation={geoLocation} />
                  </Container>
                </Container>
              </div>
              {/* </span> */}
              <div className="right-side">
                {!isSold ? (
                  <SideColumn
                    files={publicDocuments}
                    globalFiles={sanityPropertyTemplate?.defaultDocuments}
                    setFormOpen={setFormOpen}
                    agent={homesellAgent}
                    owner={sellerDetails}
                    listingType={packageCategory}
                    status={status}
                  />
                ) : (
                  <p className="sold-property">SOLD</p>
                )}
              </div>
            </div>
          </PageWidth>
        </SectionTop>
      </Container>
      {propertyId && (
        <Drawer visible={formOpen} onClose={() => setFormOpen(false)}>
          <PropertyEoiForm property={property} />
        </Drawer>
      )}
      {sanityPropertyTemplate?.components?.map(component => (
        <Component key={component?._key} data={component} />
      ))}
      <FooterSections pageFooter={sanityPropertyTemplate?.pageFooter} />
    </PropertyContext.Provider>
  );
};

export default PropertyInner;

const SectionTop = styled(Section)`
  padding: 80px 0 10px;

  .price-container {
    font-size: 26px;
    margin-bottom: 15px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 35px 0 10px;
  }

  h5,
  h4 {
    margin-bottom: 10px;
  }

  .open-homes {
    width: 100%;
    margin-bottom: 50px;
  }

  .content-area {
    display: flex;
    gap: 30px;
    width: 100%;

    ${mediaQuery.tabletDown} {
      flex-direction: column;
    }

    .left-side {
      width: calc(70% - 30px);

      ${mediaQuery.tabletDown} {
        width: 100%;
      }
    }
    .right-side {
      width: 30%;

      ${mediaQuery.tabletDown} {
        display: none;
      }
    }
  }

  .sold-property {
    background: ${colors.navy};
    color: ${colors.white};
    padding: 10px 20px;
    font-size: 20px;
  }
`;

const H1 = styled.h1`
  display: inline-block;
  width: 100%;
  border-bottom: 1.5px solid ${colorsRGB.main(0.3)};
  padding-bottom: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`;
