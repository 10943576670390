import { graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { PageContext } from "@util/types";
import { Maybe, Query, SanityProperty, SanityPropertyTemplate } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import styled from "styled-components";
import { toast } from "react-toastify";
import { getSanityProperty } from "@util/helper";
import { Button } from "@global";
import PropertyInner from "@global/property/propertyInner";

interface Props {
  data: Query;
  pageContext: PageContext;
}

const Preview = ({ data, pageContext }: Props) => {
  const [pageId, setPageId] = useState<string | null>();
  const [property, setProperty] = useState<{
    sanityPropertyTemplate: Maybe<SanityPropertyTemplate> | undefined;
    sanityProperty: Maybe<SanityProperty>;
  }>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setPageId(id);
  }, []);

  useEffect(() => {
    if (!pageId || property) return;

    const fetchProperty = async () => {
      const property = await getSanityProperty(pageId);

      if (property) {
        setProperty({
          sanityPropertyTemplate: data.sanityPropertyTemplate,
          sanityProperty: property[0],
        });
      } else {
        toast.error("Error on load the preview, please try again");
      }
    };
    fetchProperty();
  }, [pageId, property]);

  if (!pageId)
    return (
      <StyledPreviewMeta>
        <h5>
          You did not provide any property ID. Please navigate to this page by clicking on the
          Preview button in My Homesell
        </h5>
      </StyledPreviewMeta>
    );

  if (property) {
    return (
      <>
        <StyledPreviewMeta backgroundColor="main">
          <h2>Property Preview</h2>
          <Button theme="whiteOutline" onClick={() => navigate("/account")}>
            Go back
          </Button>
        </StyledPreviewMeta>
        <PropertyInner data={property} pageContext={pageContext} />;
      </>
    );
  }

  return (
    <Section>
      <PageWidth>
        <h5>Loading preview...</h5>
      </PageWidth>
    </Section>
  );
};

const StyledPreviewMeta = styled(Container)`
  padding: 50px;
  min-height: 200px;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    color: #fff;
    text-align: center;
  }
`;

export default Preview;

export const query = graphql`
  query preview {
    sanityPropertyTemplate {
      components {
        ...sanityComponents
      }
      pageFooter {
        ...sanityPageFooter
      }
      defaultDocuments {
        _key
        _type
        fileName
        fileLink
        file {
          asset {
            url
          }
        }
      }
    }
  }
`;
